import React from 'react';
import { Helmet } from 'react-helmet';

type Props = Record<string, unknown>;
const Shooting: React.FC<Props> = () => (
  <Helmet
    htmlAttributes={{
      lang: 'de',
    }}
  >
    <meta property="og:url" content="https://didimedia.lpages.co/nich-hahne/" />
    <link rel="opengraph" href="https://didimedia.lpages.co/nich-hahne/" />
    <script src="https://didimedia.lpages.co/_/js/nich-hahne/"></script>
  </Helmet>
);

export default Shooting;
